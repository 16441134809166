// 
// _badge.scss
// 


// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.1);
}

@each $color,
$value in $theme-colors {
  .badge-soft-#{$color} {
    @include badge-variant-soft($value);
  }
}

// Outline badge
@mixin badge-variant-outline($bg) {
  color: $bg;
  border: 1px solid $bg;
  background-color: transparent;
}



@each $color,
$value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-variant-outline($value);
  }
}

.text-bg-primary {
  color: $white !important;
}

.text-bg-secondary {
  color: $white !important;
}

.text-bg-danger {
  color: $white !important;
}

.text-bg-success {
  color: $white !important;
}

.text-bg-warning {
  color: $white !important;
}

.text-bg-info {
  color: $white !important;
}



.badge {

  &.bg-light,
  &.badge-soft-light,
  &.badge-outline-light {
    color: $dark;
  }
}

.badge-dark {
  color: $gray-200;
}

.btn-close {
  background-image: none !important;
}